/**
 * NOTE: There's a big ol' stanky bug with styled-components where dynamically
 * updating styles based on prop changes rerenders the entire stylesheet,
 * including font-face declarations.
 *
 * Render this as separate CSS so it will be added separately from the
 * styled-components tag.
 *
 * https://github.com/styled-components/styled-components/issues/400
 */
@font-face {
  font-family: 'Rockness';
  /* src: url('/fonts/rockness/Rockness.ttf') format('ttf'), url('/fonts/rockness/Rockness.otf') format('opentype'); */
  src: url('/fonts/rockness/Rockness.otf') format('opentype');
  font-style: normal;
  font-display: auto;
}
